import { VStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";

import { StepSubmit } from "../../components/StepSubmit/StepSubmit";
import { useSecondarySellerStep } from "./useSecondarySellerStep";
import { SellerSchema, getNameAndLabel } from "./utils";

export const inputMaxWidth = "64";

export function SecondarySellerStep() {
  const { initialValues, onSubmit, isSubmitting } = useSecondarySellerStep();

  return (
    <Form
      onSubmit={onSubmit}
      config={{
        schema: SellerSchema,
        defaultValues: initialValues.form,
      }}
    >
      <Typography variant="body" color="grey.gunsmoke" mb="m">
        Please share contact details for the person or company selling the
        shares in this deal. We need them to complete identity verification
        before you can publish this deal.
      </Typography>

      <VStack alignItems="flex-start" gap="8" w="full">
        <Form.TextField
          {...getNameAndLabel("name")}
          placeholder="Patrick"
          width={["full", inputMaxWidth]}
        />

        <Form.TextField
          {...getNameAndLabel("email")}
          placeholder="hello@joinodin.com"
          width={["full", inputMaxWidth]}
        />
      </VStack>
      <StepSubmit isLoading={isSubmitting} />
    </Form>
  );
}
