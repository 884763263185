import { Box } from "@@panda/jsx";
import { FieldRow } from "components/display/FieldRow";
import { Link } from "components/interaction/Link";
import { RichText } from "components/interaction/RichText";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";

import { useMaterialsSection } from "./useMaterialsSection";

export function MaterialsSection() {
  const section = useMaterialsSection();

  return (
    <>
      <FieldRow
        label="Memo"
        value={
          <Dialog title="Memo preview">
            <Dialog.Trigger>
              <Button
                variant="secondary"
                mb="2"
                data-testid="memo-preview-button"
              >
                Preview
              </Button>
            </Dialog.Trigger>
            <Dialog.Content>
              <Box maxH="70vh" overflow="auto" pr="4" pl="2">
                <RichText type="viewer" initialContent={section.memoHtml} />
              </Box>
            </Dialog.Content>
          </Dialog>
        }
      />
      {section.deck_embed ? (
        <FieldRow
          label="Embedded deck"
          value={
            <Dialog title="Deck embed preview">
              <Dialog.Trigger>
                <Button
                  variant="secondary"
                  mb="2"
                  data-testid="deck-embed-preview-button"
                >
                  Preview
                </Button>
              </Dialog.Trigger>

              <Dialog.Content>
                <Box maxH="70vh" overflow="auto" pr="4" pl="2">
                  <iframe
                    title="Deck preview"
                    width="100%"
                    height="400px"
                    src={section.deck_embed.url}
                    data-testid="deck-embed-iframe"
                  />
                </Box>
              </Dialog.Content>
            </Dialog>
          }
        />
      ) : null}
      <FieldRow
        label="Investment agreement"
        value={
          <Link variant="secondary" href={section.agreement.url} isExternal>
            {section.agreement.display}
          </Link>
        }
      />
      <FieldRow
        label="Pitch deck"
        value={
          <Link variant="secondary" href={section.deck.url} isExternal>
            {section.deck.display}
          </Link>
        }
      />
    </>
  );
}
