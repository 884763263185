import { Box } from "@@panda/jsx";
import { Form } from "components/forms/Form/Form";
import { FileUpload } from "components/interaction/FileUpload/FileUpload";
import { Button } from "components/main/Button";
import { useState } from "react";

import { inputMaxWidth } from "../../MemoMaterialsStep";
import { getNameAndLabel } from "../../utils";

export function DeckField() {
  const [mode, setMode] = useState<"text" | "file">("text");

  const urlSubLabel = (
    <>
      Link to a Google Drive or similar.
      <br />
      Please ensure the document is not blank and can be viewed by anyone who
      has the link.
    </>
  );

  const fileSubLabel = <>File must be in PDF format.</>;

  return (
    <Box w="full">
      {mode === "text" ? (
        <Form.URLEmbed
          {...getNameAndLabel("pitch_deck")}
          width={["full", inputMaxWidth]}
          subLabel={urlSubLabel}
        />
      ) : (
        <FileUpload
          {...getNameAndLabel("pitch_deck")}
          subLabel={fileSubLabel}
          singleFileMode
        />
      )}

      <Button
        variant="link"
        size="sm"
        onClick={() => setMode((m) => (m === "file" ? "text" : "file"))}
      >
        {mode === "file" ? "Or provide a URL" : "Or upload a file"}
      </Button>
    </Box>
  );
}
