import { css, cx } from "@@panda/css";
import { Box, HStack, HTMLStyledProps, VStack } from "@@panda/jsx";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { AlertDescription } from "./components/AlertDescription";
import { AlertTitle } from "./components/AlertTitle";
import { AlertContext } from "./context";
import { Variant, alertStyles, statusToIconMap } from "./utils";

Alert.Title = AlertTitle;
Alert.Description = AlertDescription;

type Props = {
  variant: Variant;
  onClose?: () => void;
  className?: string;
  alignIcon?: "center" | "flex-start";
} & HTMLStyledProps<"div">;

export function Alert({
  children,
  onClose,
  className,
  variant,
  alignIcon = "center",
  ...rest
}: Props) {
  const icon = statusToIconMap[variant];
  const styles = alertStyles({ variant });

  return (
    <AlertContext.Provider value={{ variant }}>
      <Box role="alert" className={cx(styles.root, className)} {...rest}>
        {onClose ? (
          <button
            aria-label="Close"
            onClick={onClose}
            className={css({
              position: "absolute",
              width: "17px",
              height: "17px",
              top: "3px",
              right: "3px",
            })}
          >
            <XMarkIcon
              className={css({
                color: "grey.minecraft",
                width: "100%",
                height: "100%",
              })}
              aria-hidden
            />
          </button>
        ) : null}

        <Box width="full" alignItems="center">
          <VStack alignItems="flex-start" gap="xs">
            <HStack width="full" alignItems={alignIcon} gap="xs">
              <Box width="100%" w="25px" flexShrink={0}>
                {icon}
              </Box>

              <VStack alignItems="flex-start" gap="s">
                {children}
              </VStack>
            </HStack>
          </VStack>
        </Box>
      </Box>
    </AlertContext.Provider>
  );
}
